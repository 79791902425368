import React from "react"
import Layout from "../components/layout"
import "./success.sass"

const Success = () => {
  return (
    <div>
      <Layout>
        <div className="success-wrapper">
          <h1>Form submission failed</h1>
          <h4>There was a problem while submitting the form</h4>
          <a
            href
            class="btn btn-lg btn-primary"
            onclick="window.history.back(); return false;"
          >
            Click here to go back
          </a>
        </div>
      </Layout>
    </div>
  )
}

export default Success
